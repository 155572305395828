import PropTypes from "prop-types"
import React, { Component } from "react";
import ReactPlayer from "react-player"


import{ IconPlayCircle} from "./svg/index"

//import{ Logo } from "./svg/index"

class Video extends Component {
  constructor(props){
    super(props);
    this.state={
      menu:false
    };
  }

  _videoEnded(){
    console.log('video has been played until the end');

    // window.dataLayer = window.dataLayer || [];

    // const dataLayer = {
    //   'event':'video_complete',
    //   'videoPlayedUntilTheEnd':true
    // }
    // window.dataLayer.push(dataLayer);


    gtag('event', "video_ended", {
      'event_label': "Actonel Video fully watched",
      'value': "videoPlayedUntilTheEnd",
      'non_interaction': true
    });

    //console.log(window.dataLayer)

    //window.gtag('send', 'event', 'videoPlayedUntilTheEnd', 'Finished', 'Video Actonel');
  }

  render(){

    return(
      <div className="row h-100 mx-0 video">
        <div className="col-12 h-100 align-items-center p-0 text-center">
          <div className="row h-100 align-items-center mx-auto video-ph">
            <div className="col-12 video-float">
              <ReactPlayer 
                className="video-ctn"
                controls={true}
                height="100%"
                light="/video_actonel.png"
                onEnded={this._videoEnded.bind(this)}
                playIcon={<div className="icon"><div className="arrow"></div></div>}
                playing={true}
                url="/video_actonel.mp4"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Video.propTypes = {
  siteTitle: PropTypes.string,
}

Video.defaultProps = {
  siteTitle: ``,
}

export default Video
