import * as React from "react"

function SvgLogo(props) {
  return (
    <svg
      id="LOGO_svg__Calque_1"
      data-name="Calque 1"
      viewBox="0 0 107.4 81.32"
      height="1em"
      {...props}
    >
      <defs>
        <style>{".LOGO_svg__cls-1{fill:currentColor}"}</style>
      </defs>
      <g id="LOGO_svg__Groupe_1" data-name="Groupe 1">
        <g id="LOGO_svg__Groupe_285" data-name="Groupe 285">
          <g id="LOGO_svg__Groupe_284" data-name="Groupe 284">
            <path
              id="LOGO_svg__Trac\xE9_448"
              data-name="Trac\xE9 448"
              className="LOGO_svg__cls-1"
              d="M0 40.1v18.72h3V40.1z"
            />
            <g id="LOGO_svg__Groupe_283" data-name="Groupe 283">
              <path
                id="LOGO_svg__Trac\xE9_449"
                data-name="Trac\xE9 449"
                className="LOGO_svg__cls-1"
                d="M81.18 49.05c.77-1.18 5.3-8.14 5.5-8.46l.28-.43H83.4l-.08.13c0 .06-3.14 4.89-4.06 6.3-.92-1.42-4-6.25-4.05-6.3l-.09-.13h-3.56l.28.43c.21.32 4.73 7.29 5.5 8.46-.8 1.23-6 9.2-6.06 9.28l-.28.43h3.56l.08-.12s3.6-5.52 4.62-7.07l4.62 7.07.08.12h3.55l-.28-.43-6.06-9.28"
              />
              <path
                id="LOGO_svg__Trac\xE9_450"
                data-name="Trac\xE9 450"
                className="LOGO_svg__cls-1"
                d="M65.14 44.53v9.9c0 .68-.21 1.59-2 1.59h-5.28c-1.81 0-2-.8-2-1.59v-9.9c0-.68.21-1.6 2-1.6h5.23c1.81 0 2 .81 2 1.6m-1-4.37h-7.27A4.05 4.05 0 0052.74 44v11a4 4 0 004.07 3.82h7.3a4.06 4.06 0 004.1-3.82V44a4.05 4.05 0 00-4.07-3.81"
              />
              <path
                id="LOGO_svg__Trac\xE9_451"
                data-name="Trac\xE9 451"
                className="LOGO_svg__cls-1"
                d="M43.91 54.48l-7.29-11.89-.08-.13-1-1.52a2.65 2.65 0 00-2.07-1 2.28 2.28 0 00-2.26 2.13v16.68h3.07V44.39c1.49 2.42 5.05 8.25 5.05 8.25l.08.13c.44.68 2.57 4 3.43 5.4A2.39 2.39 0 0047 57.12v-17h-3.09z"
              />
              <path
                id="LOGO_svg__Trac\xE9_452"
                data-name="Trac\xE9 452"
                className="LOGO_svg__cls-1"
                d="M22 54.47l-7.33-11.88-.09-.13-1-1.52a2.62 2.62 0 00-2.07-1 2.28 2.28 0 00-2.27 2.13v16.68h3.07V44.39l5.13 8.38c.42.65 2.37 3.71 3.44 5.4a2.06 2.06 0 001.85.8A2 2 0 0025 57.19a.2.2 0 000-.07v-17h-3z"
              />
              <path
                id="LOGO_svg__Trac\xE9_453"
                data-name="Trac\xE9 453"
                className="LOGO_svg__cls-1"
                d="M101 51.11h-5.31l2.68-6 1.74 3.89.93 2.09M100 41.26a1.71 1.71 0 00-1.56-1.14h-.1a1.69 1.69 0 00-1.53 1.14l-3.84 9.07c-1.67 3.93-3.39 8-3.44 8.12l-.18.39h3.38l.07-.18c.25-.62 1-2.59 1.37-3.77l.28-.8h7.9c.09.24.26.69.43 1.17.42 1.2 1 2.84 1.24 3.4l.07.18h3.36z"
              />
            </g>
          </g>
        </g>
        <g id="LOGO_svg__Groupe_287" data-name="Groupe 287">
          <g id="LOGO_svg__Groupe_286" data-name="Groupe 286">
            <path
              id="LOGO_svg__Trac\xE9_454"
              data-name="Trac\xE9 454"
              className="LOGO_svg__cls-1"
              d="M4.54 65c-2.19 4.91-4 9.2-3.87 12a4.75 4.75 0 000 .54A4 4 0 001.9 80a2.62 2.62 0 00.6.45 3.76 3.76 0 001.8.4h.1a7.26 7.26 0 00.93-.06c4.46-.57 9.32-4 15.73-11 1.57-1.76 3-3.47 4.15-5 .45-.6-.21-1.23-1.15-1.1a1.43 1.43 0 00-.92.46c-.91 1.16-2.11 2.57-3.76 4.32-3.71 4-4.53 5.16-6.48 5.57a4.07 4.07 0 01-2.17-.37 1.79 1.79 0 01-1-1.42v-.2c-.07-1.4.51-3 1.84-6.16l2-4.36H6.14z"
            />
            <path
              id="LOGO_svg__Trac\xE9_455"
              data-name="Trac\xE9 455"
              className="LOGO_svg__cls-1"
              d="M26.21 34.72l.65-1.43c.47-1.09.9-2.17 1.27-3.21a24.21 24.21 0 001.76-7.5 4.75 4.75 0 00-.05-.53 3.27 3.27 0 00-1.6-2.62 5 5 0 00-3-.37c-4.75.61-9.93 4.24-16.77 11.76A42.72 42.72 0 005 34.89l-1 1.33c-.47.64.23 1.31 1.22 1.18a1.58 1.58 0 001-.5c1-1.24 2.25-2.74 4-4.61 3.94-4.21 5.72-5.94 7.94-6.46a3.3 3.3 0 011.57.34h.05a1.85 1.85 0 011 1.52v.29a10.56 10.56 0 01-.78 3.91c-.38 1-.9 2.29-1.59 3.81l-.81 1.7h7.3z"
            />
          </g>
          <ellipse
            id="LOGO_svg__Ellipse_108"
            data-name="Ellipse 108"
            className="LOGO_svg__cls-1"
            cx={33.27}
            cy={6.75}
            rx={7.11}
            ry={4.41}
            transform="rotate(-66.18 33.27 6.749)"
          />
        </g>
        <g id="LOGO_svg__Groupe_288" data-name="Groupe 288">
          <path
            id="LOGO_svg__Trac\xE9_456"
            data-name="Trac\xE9 456"
            className="LOGO_svg__cls-1"
            d="M31.34 64.35H36v1h-3.57V67h3.13v1h-3.13v2.53h-1.09z"
          />
          <path
            id="LOGO_svg__Trac\xE9_457"
            data-name="Trac\xE9 457"
            className="LOGO_svg__cls-1"
            d="M39.81 67.47a3.25 3.25 0 016.49 0 3.25 3.25 0 01-6.49 0zm5.35 0a2.13 2.13 0 00-2-2.21h-.06a2.1 2.1 0 00-2.1 2.1v.11a2.13 2.13 0 002 2.2h.07a2.09 2.09 0 002.1-2.09z"
          />
          <path
            id="LOGO_svg__Trac\xE9_458"
            data-name="Trac\xE9 458"
            className="LOGO_svg__cls-1"
            d="M50.55 64.35h2.77a2.55 2.55 0 011.79.62 1.91 1.91 0 01.51 1.34 1.86 1.86 0 01-1.47 1.88l1.68 2.35h-1.3L53 68.4h-1.36v2.16h-1.09zm2.69 3.08c.78 0 1.27-.41 1.27-1 0-.66-.47-1-1.28-1h-1.59v2.09z"
          />
          <path
            id="LOGO_svg__Trac\xE9_459"
            data-name="Trac\xE9 459"
            className="LOGO_svg__cls-1"
            d="M59.88 64.35h1.17l1.89 2.94 1.89-2.94H66v6.21h-1.1v-4.45l-2 2.93-1.9-2.91v4.43h-1.12z"
          />
          <path
            id="LOGO_svg__Trac\xE9_460"
            data-name="Trac\xE9 460"
            className="LOGO_svg__cls-1"
            d="M70.41 67.93v-3.58h1.09v3.53c0 1.16.59 1.77 1.57 1.77s1.56-.58 1.56-1.72v-3.58h1.09v3.52a2.49 2.49 0 01-2.15 2.78 3 3 0 01-.52 0 2.45 2.45 0 01-2.65-2.23 2.77 2.77 0 01.01-.49z"
          />
          <path
            id="LOGO_svg__Trac\xE9_461"
            data-name="Trac\xE9 461"
            className="LOGO_svg__cls-1"
            d="M80.15 64.35h1.09v5.22h3.26v1h-4.35z"
          />
          <path
            id="LOGO_svg__Trac\xE9_462"
            data-name="Trac\xE9 462"
            className="LOGO_svg__cls-1"
            d="M88.56 64.35h4.6v1h-3.51v1.61h3.11v1h-3.11v1.67h3.56v1h-4.65z"
          />
          <path
            id="LOGO_svg__Trac\xE9_463"
            data-name="Trac\xE9 463"
            className="LOGO_svg__cls-1"
            d="M31.34 75h2.79a2.24 2.24 0 011.62.55 1.41 1.41 0 01.42 1 1.47 1.47 0 01-.89 1.45 1.52 1.52 0 011.23 1.51c0 1.11-.91 1.7-2.3 1.7h-2.87zm3.73 1.76c0-.49-.38-.79-1.09-.79h-1.57v1.65h1.49c.7 0 1.18-.28 1.18-.84zm-.93 1.78h-1.73v1.7h1.81c.75 0 1.2-.29 1.2-.85 0-.54-.42-.85-1.28-.85z"
          />
          <path
            id="LOGO_svg__Trac\xE9_464"
            data-name="Trac\xE9 464"
            className="LOGO_svg__cls-1"
            d="M40.67 75h1.09v5.22H45v1h-4.33z"
          />
          <path
            id="LOGO_svg__Trac\xE9_465"
            data-name="Trac\xE9 465"
            className="LOGO_svg__cls-1"
            d="M49.08 75h4.6v1h-3.51v1.61h3.12v1h-3.12v1.67h3.56v1h-4.65z"
          />
          <path
            id="LOGO_svg__Trac\xE9_466"
            data-name="Trac\xE9 466"
            className="LOGO_svg__cls-1"
            d="M57.85 78.58V75h1.09v3.53c0 1.16.6 1.77 1.57 1.77s1.56-.58 1.56-1.72V75h1.1v3.52A2.49 2.49 0 0161 81.3a3 3 0 01-.52 0 2.45 2.45 0 01-2.66-2.22 2.9 2.9 0 01.03-.5z"
          />
          <path
            id="LOGO_svg__Trac\xE9_467"
            data-name="Trac\xE9 467"
            className="LOGO_svg__cls-1"
            d="M67.59 75h4.6v1h-3.51v1.61h3.11v1h-3.11v1.67h3.55v1h-4.64z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgLogo
